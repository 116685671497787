import axios from '../services/axios';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import './Ninebox.css';
import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from 'react-router-dom';
import { faCircleQuestion, faXmark} from '@fortawesome/free-solid-svg-icons';
import { Box, Modal } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';

export default function Ninebox(props) {

            const location = useLocation();
            const supervisorId = location.state.supervisorId;
            const [loading, setLoading] = useState(true);
            const [isHover, setIsHover] = useState(false);
            const [hoverItem, setHoverItem] = useState("");
            const [showModal, setShowModal] = useState(false);
            const [modalText, setModalText] = useState("");
            const [modalHeader, setModalHeader] = useState("");
            const [successorId, setSuccessorId] = useState(0);
            const [rankingStatus, setRankingStatus] = useState({
                unrankedMembers: {
                    id: 0,
                    name: "Team",
                    description: "",
                    questionHidden: true,
                    items: []
                },
                rank1: {
                    id: 1,
                    name: "Potential Star  ",
                    description: "You are highly driven and ambitiously aspire to move quickly upward in the company.  You may be new to the company or new to the industry and haven’t fully demonstrated track record of performance as you may still be learning the ropes.  Eager to learn.",
                    questionHidden: false,
                    items: []
                },
                rank2: {
                    id: 2,
                    name: "Future Leader ",
                    description: "You have a high capacity and ambition to expand in role, scope and complexity; you have solid performance; willingness to go where the opportunity is.",
                    questionHidden: false,
                    items: []
                },
                rank3: {
                    id: 3,
                    name: "Leader  ",
                    description: "You are delivering exceptional performance results that are consistent and sustainable; you also possess enormous drive for achievement and ambition; you are a great role model for both “what” and “how” results are achieved; well equipped to take on any assignment; considered High Potential employees.",
                    questionHidden: false,
                    items: []
                },
                rank4: {
                    id: 4,
                    name: "Potential Performer  ",
                    description: "Possibly you are new to the role and might not have learned the ropes yet but have the potential.  Or perhaps have been at iVenture but performance has dropped due to personal or professional things going on.",
                    questionHidden: false,
                    items: []
                },
                rank5: {
                    id: 5,
                    name: "Core Player  ",
                    description: "You are a reliable performer and are well placed.  You have the opportunity to grow further in your current role.  You may not be ready for a next role now but have the potential and willingness to in the future.",
                    questionHidden: false,
                    items: []
                },
                rank6: {
                    id: 6,
                    name: "High Impact Performer  ",
                    description: "You are a high performer with consistent results and are a worry-free candidate for future growth and development beyond current position. You have the potential for a greater impact but it could be just a matter of readiness that might prevent you from moving into those larger roles just yet.",
                    questionHidden: false,
                    items: []
                },
                rank7: {
                    id: 7,
                    name: "Under Performer  ",
                    description: "Need to have to have some tenure before falling into this category; performance results are inconsistent, and you may not have the willingness or ready for more at this time.",
                    questionHidden: false,
                    items: []
                },
                rank8: {
                    id: 8,
                    name: "Stable Contributor  ",
                    description: "You are a good fit for your current role and comfortable where you are at.",
                    questionHidden: false,
                    items: []
                },
                rank9: {
                    id: 9,
                    name: "Trusted Professional  ",
                    description: "You are recognized for a valued, unique skill set, often exceeding expectations, you typically enjoy your area of expertise and do not desire upward mobility.",
                    questionHidden: false,
                    items: []
                },
                horizHeader1: {
                    id:0,
                    name: "Inconsistent/Does Not Meet",
                    description: "",
                    questionHidden: true,
                    items: []
                },
                horizHeader2: {
                    id:0,
                    name: "Consistently Meets",
                    description: "",
                    questionHidden: true,
                    items: []
                },
                horizHeader3: {
                    id:0,
                    name: "Consistently Exceeds",
                    description: "",
                    questionHidden: true,
                    items: []
                },
            });

    useEffect(() => {
        const fetchTeamMembers = async () => {
            try {
                const results = await axios.get(`Ninebox/GetTeamMembers?supervisorId=${supervisorId}`);
                if (results.data.length > 0) {
                    let teamMembers = results.data;
                    // Create a new copy of rankingStatus instead of mutating it directly
                    let rankingStatusUpdate = {
                        ...rankingStatus,
                        unrankedMembers: { ...rankingStatus.unrankedMembers, items: [] },
                        rank1: { ...rankingStatus.rank1, items: [] },
                        rank2: { ...rankingStatus.rank2, items: [] },
                        rank3: { ...rankingStatus.rank3, items: [] },
                        rank4: { ...rankingStatus.rank4, items: [] },
                        rank5: { ...rankingStatus.rank5, items: [] },
                        rank6: { ...rankingStatus.rank6, items: [] },
                        rank7: { ...rankingStatus.rank7, items: [] },
                        rank8: { ...rankingStatus.rank8, items: [] },
                        rank9: { ...rankingStatus.rank9, items: [] }
                    };

                    // Process each team member
                    teamMembers.forEach(member => {
                        switch (member.ranking) {
                            case 0:
                                if (!memberDuplicateCheck(member, rankingStatusUpdate.unrankedMembers.items)) {
                                    rankingStatusUpdate.unrankedMembers.items.push(member);
                                }
                                break;
                            case 1:
                                if (!memberDuplicateCheck(member, rankingStatusUpdate.rank1.items)) {
                                    rankingStatusUpdate.rank1.items.push(member);
                                }
                                break;
                            case 2:
                                if (!memberDuplicateCheck(member, rankingStatusUpdate.rank2.items)) {
                                    rankingStatusUpdate.rank2.items.push(member);
                                }
                                break;
                            case 3:
                                if (!memberDuplicateCheck(member, rankingStatusUpdate.rank3.items)) {
                                    rankingStatusUpdate.rank3.items.push(member);
                                }
                                break;
                            case 4:
                                if (!memberDuplicateCheck(member, rankingStatusUpdate.rank4.items)) {
                                    rankingStatusUpdate.rank4.items.push(member);
                                }
                                break;
                            case 5:
                                if (!memberDuplicateCheck(member, rankingStatusUpdate.rank5.items)) {
                                    rankingStatusUpdate.rank5.items.push(member);
                                }
                                break;
                            case 6:
                                if (!memberDuplicateCheck(member, rankingStatusUpdate.rank6.items)) {
                                    rankingStatusUpdate.rank6.items.push(member);
                                }
                                break;
                            case 7:
                                if (!memberDuplicateCheck(member, rankingStatusUpdate.rank7.items)) {
                                    rankingStatusUpdate.rank7.items.push(member);
                                }
                                break;
                            case 8:
                                if (!memberDuplicateCheck(member, rankingStatusUpdate.rank8.items)) {
                                    rankingStatusUpdate.rank8.items.push(member);
                                }
                                break;
                            case 9:
                                if (!memberDuplicateCheck(member, rankingStatusUpdate.rank9.items)) {
                                    rankingStatusUpdate.rank9.items.push(member);
                                }
                                break;
                            default:
                                console.log(`Unhandled ranking case: ${member.ranking}`);
                                break;
                        }
                    });

                    setLoading(false);
                    setRankingStatus(rankingStatusUpdate);
                }
            } catch (error) {
                console.error('Error fetching team members:', error);
                setLoading(false);
            }
        };

        fetchTeamMembers();
        // eslint-disable-next-line
    }, [supervisorId]);

    const memberDuplicateCheck = (teamMember, memberList) => {
        for(let i = 0; i < memberList.length; i++) {
            if(memberList[i].employeeId === teamMember.employeeId) {
                return true;
            }
        }
        return false;
    }

    const updateMemberRanking = async (employeeId, employeeRanking, loggedOnUserEmployeeId) => {
        const rankingData = {
            employeeId: Number(employeeId),
            ranking: Number(employeeRanking)
        };

        const auditData = {
            employeeId: Number(employeeId),
            score: Number(employeeRanking),
            setBy: Number(loggedOnUserEmployeeId)
        };

        try {
            await axios.post('NineBox', rankingData);
            await axios.post('NineBox/Audit', auditData);
        } catch (error) {
            console.error('Error updating ranking:', error);
            throw error;
        }
    };

    useEffect(() => {
        getSuccessor();
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supervisorId]);

    const getSuccessor = useCallback(async () => {
        try {
            const results = await axios.get(`Ninebox/GetSuccessors`);
            if (results.data.length > 0) {
                let currentSuccessor = results.data.find(successor => successor.supervisorId === supervisorId);
                setSuccessorId(currentSuccessor ? currentSuccessor.successorId : 0);
            }
        } catch (error) {
            console.log(error);
        }
    }, [supervisorId]);

    const handleOnDragEnd = async(result) => {
        if (!result.destination) return;
        console.log(result)
        const { source, destination } = result;

        if (source.droppableId !== destination.droppableId) {
            const items = rankingStatus;
            const [reorderedItem] = items[source.droppableId].items.splice(result.source.index, 1); //remove from source

            let newList = items[destination.droppableId];
            const updatedList = newList.items;
            updatedList.splice(result.destination.index, 0, reorderedItem);
            items[destination.droppableId].items = updatedList;

            await updateMemberRanking(reorderedItem.employeeId,rankingStatus[destination.droppableId].id,props.loggedOnUserEmployeeId);

            setRankingStatus(items);
        } else {
            let updatedMembers = rankingStatus;
            const items = Array.from(updatedMembers[destination.droppableId].items);
            const [reorderedItem] = items.splice(source.index, 1);
            items.splice(destination.index, 0, reorderedItem);
            updatedMembers[destination.droppableId].items = items;

            setRankingStatus(updatedMembers);
        }
    }

    const handleMouseEnter = (event) => {
        setIsHover(true);
        setHoverItem(event.target.dataset.rfdDraggableId);
    }

    const handleMouseLeave = (event) => {
        setIsHover(false);
    }

    
    const selectModal = (columnId) => {
        switch (columnId) {
            case "rank1": {     
                setModalText(rankingStatus.rank1.description)
                setModalHeader(rankingStatus.rank1.name)
                break;
            }
            case "rank2": {
                setModalText(rankingStatus.rank2.description)
                setModalHeader(rankingStatus.rank2.name)
                break;
            }
            case "rank3": {
                setModalText(rankingStatus.rank3.description)
                setModalHeader(rankingStatus.rank3.name)
                break;
            }
            case "rank4": {
                setModalText(rankingStatus.rank4.description)
                setModalHeader(rankingStatus.rank4.name)
                break;
            }
            case "rank5": {
                setModalText(rankingStatus.rank5.description)
                setModalHeader(rankingStatus.rank5.name)
                break;
            }
            case "rank6": {
                setModalText(rankingStatus.rank6.description)
                setModalHeader(rankingStatus.rank6.name)
                break;
            }
            case "rank7": {
                setModalText(rankingStatus.rank7.description)
                setModalHeader(rankingStatus.rank7.name)
                break;
            }
            case "rank8": {
                setModalText(rankingStatus.rank8.description)
                setModalHeader(rankingStatus.rank8.name)
                break;
            }
            case "rank9": {
                setModalText(rankingStatus.rank9.description)
                setModalHeader(rankingStatus.rank9.name)
                break;
            }
            default: {
                setModalText("");
            }      
        }
    }

    
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    color : '#ffffff',
    bgcolor: '#666666',
    borderRadius: '5px',
    border: '2px solid #000000',
    boxShadow: 24,
    p: 4,
  };

    const openModal = (columnId) => {
        selectModal(columnId);
        setShowModal(true);
    }
    
    const closeModal = () => setShowModal(false);



        return (
            <div className="App">

                {!loading ?
                <header className="App-header">
                        <div>
                            <Modal
                                open={showModal}
                                onClose={closeModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={modalStyle}>
                                    <FontAwesomeIcon
                                        fontSize='24px'
                                        color='black'
                                        icon={faXmark}
                                        onClick={() =>
                                            closeModal()
                                        }
                                        id='modalX'
                                    />
                                    <h2 className='modalHeader' id="modal-modal-title" variant="h6" component="h2">
                                        {modalHeader}
                                    </h2>
                                    <p className='modalText' id="modal-modal-description" sx={{ mt: 2 }}>
                                        {modalText}
                                    </p>
                                </Box>
                            </Modal>
                        </div>
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <div className="allRankingBoxes">
                                <div className="vertArrow">
                                    <br></br>
                                    <img src="/Potential.svg" alt="Potential arrow indicator"></img>
                                </div>
                                <div className="vertHeaderColumn">
                                    <div className="vertHeader">
                                        <p>High</p>
                                    </div>
                                    <div className="vertHeader">
                                        <p>Moderate</p>
                                    </div>
                                    <div className="vertHeader">
                                        <p>Limited</p>
                                    </div>
                                </div>
                                <div className="rankedMembers">
                                    {Object.entries(rankingStatus).slice(1).map(([columnId, column], index) => {
                                        return (
                                            <div key={columnId} className={columnId}>
                                                <p >{column.name}  
                                                <FontAwesomeIcon
                                                        fontSize={column.questionHidden  ? '0px' : '16px'}
                                                        color='black'
                                                        icon={faCircleQuestion}
                                                        onClick= { ()=> 
                                                            openModal(columnId)
                                                        }
                                                    />
                                                </p>
                                                <div className={column.questionHidden ? 'teamMembersHidden' : 'teamMembersShown'}>
                                                    <Droppable droppableId={columnId} key={columnId}>
                                                        {(provided, snapshot) => (
                                                            <div className="teamMembers" {...provided.droppableProps} ref={provided.innerRef} >
                                                                {column.items.map((item, index) => {
                                                                    return (
                                                                        <Draggable key={item.employeeId} draggableId={item.employeeId.toString()} index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <li key={item.employeeId} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                                    onMouseEnter={handleMouseEnter.bind(this)}
                                                                                    onMouseLeave={handleMouseLeave.bind(this)}

                                                                                >
                                                                                    {isHover && hoverItem === item.employeeId ?
                                                                                        <p>{item.firstName} {item.lastName}
                                                                                            <br /> {item.title} </p>
                                                                                        :
                                                                                        <p>{item.firstName} {item.lastName}</p>
                                                                                    }
                                                                                    {item.employeeId === successorId && <FontAwesomeIcon icon={faCrown} className="successorCrown" />}
                                                                                </li>
                                                                            )}
                                                                        </Draggable>
                                                                    );
                                                                })}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="unrankedMembers">
                                    {Object.entries(rankingStatus).slice(0, 1).map(([columnId, column], index) => {
                                        return (
                                            <div key={columnId}>
                                                <p>{column.name}</p>
                                                <div>
                                                    <Droppable droppableId={columnId} key={columnId}>
                                                        {(provided, snapshot) => (
                                                            <div className="teamMembers" {...provided.droppableProps} ref={provided.innerRef}>
                                                                {column.items.map((item, index) => {
                                                                    return (
                                                                        <Draggable key={item.employeeId} draggableId={item.employeeId.toString()} index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <li key={item.employeeId} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                                    onMouseEnter={handleMouseEnter.bind(this)}
                                                                                    onMouseLeave={handleMouseLeave.bind(this)}
                                                                                >
                                                                                    {isHover && hoverItem === item.employeeId ?
                                                                                        <p>{item.firstName} {item.lastName}
                                                                                            <br /> {item.title} </p>
                                                                                        :
                                                                                        <p>{item.firstName} {item.lastName}</p>
                                                                                    }
                                                                                    {item.employeeId === successorId && <FontAwesomeIcon icon={faCrown} className="successorCrown" />}
                                                                                </li>
                                                                            )}
                                                                        </Draggable>
                                                                    );
                                                                })}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                            </div>
                                <div className="horizArrow">
                                    <img src="/Performance.svg" alt="Performance arrow indicator"></img>
                                </div>
                        </DragDropContext>                       
                </header>
                : <h2>Loading....</h2>
                }
            </div>
        )
}

export const msalConfig = {
    auth: {
        clientId: "04282075-90a0-4fdd-9d62-3408702dfe4d",
        clientSecret: process.env.REACT_APP_CLIENT_SECRET,
        authority: "https://login.microsoftonline.com/6bf18644-aa32-43c4-9fb4-0d24fc0e32f5",
        keyVaultName: process.env.REACT_APP_KEYVAULT_NAME,
        redirectUri: process.env.NODE_ENV === 'development' ? "http://localhost:3000" : "https://kind-plant-003e2240f.5.azurestaticapps.net/",
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

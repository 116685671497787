import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import axios from "../services/axios";
import { Link } from "react-router-dom";

Modal.setAppElement("#root");

function AdminSettings({ loggedOnUserEmployeeId }) {
  const [supervisors, setSupervisors] = useState([]);
  const [selectedSupervisor, setSelectedSupervisor] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false);
  const [changedPermissions, setChangedPermissions] = useState({});
  const [confirmChanges, setConfirmChanges] = useState(false);
  const confirmButtonRef = useRef(null);
  const [successorId, setSuccessorId] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [allSupervisors, setAllSupervisors] = useState([]);

  const loadPermissionsForSupervisor = async (supervisorId) => {
    setIsLoadingPermissions(true);
    try {
      const { data: visibilityPermissions } = await axios.get(
          "Ninebox/GetVisibilityPermissions"
      );
      const { data: adminPermissionsData } = await axios.get(
          "Ninebox/GetAdminPermissions"
      );

      const initialPermissions = { ...permissions };

      // Initialize with default values
      initialPermissions[supervisorId] = {
        isAdmin: false,
        visibleTo: [],
      };

      const relatedVisibilityPermissions = visibilityPermissions.filter(
          (perm) => perm.supervisorId === supervisorId
      );

      const visibilityPerms = relatedVisibilityPermissions.map(
          (perm) => perm.supervisorIdVisibleTo
      );

      const adminPermissionObj = adminPermissionsData.find(
          (perm) => perm.supervisorId === supervisorId
      );
      const adminPermissions = adminPermissionObj
          ? adminPermissionObj.isAdmin
          : false;

      initialPermissions[supervisorId] = {
        isAdmin: adminPermissions,
        visibleTo: visibilityPerms,
      };

      setPermissions(initialPermissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    } finally {
      setIsLoadingPermissions(false);
    }
  };

  const handleEditClick = async (supervisor) => {
    setSelectedSupervisor(supervisor);
    loadPermissionsForSupervisor(supervisor.employeeId);
    await LoadEmployees();
    await LoadSuccessor();
    setIsModalOpen(true);
  };

// Modify the useEffect to store both filtered and all supervisors
  useEffect(() => {
    const getData = async () => {
      try {
        // Get both supervisors and visibility permissions
        const [supervisorsResponse, visibilityResponse] = await Promise.all([
          axios.get("Ninebox/GetSupervisors"),
          axios.get("Ninebox/GetVisibilityPermissions")
        ]);

        const allSupervisorsList = supervisorsResponse.data.sort((a, b) =>
            a.firstName.localeCompare(b.firstName)
        );
        const visibilityPermissionsData = visibilityResponse.data;

        // Store all supervisors for the modal view
        setAllSupervisors(allSupervisorsList);

        // Filter supervisors for the dropdown
        const filteredSupervisors = allSupervisorsList.filter(supervisor => {
          // Include supervisor if it's the logged in user
          if (supervisor.employeeId === loggedOnUserEmployeeId) {
            return true;
          }

          // Include supervisor if they are visible to the logged in user
          return visibilityPermissionsData.some(permission =>
              permission.supervisorId === supervisor.employeeId &&
              permission.supervisorIdVisibleTo === loggedOnUserEmployeeId
          );
        });

        setSupervisors(filteredSupervisors);
        setSelectedSupervisor(filteredSupervisors[0]);
      } catch (error) {
        console.error("Error fetching supervisors:", error);
      }
    };

    getData();
  }, [loggedOnUserEmployeeId]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAdminChange = (supervisorId) => {
    const updatedPermissions = {
      ...permissions[supervisorId],
      isAdmin: !permissions[supervisorId].isAdmin,
    };

    setPermissions({
      ...permissions,
      [supervisorId]: updatedPermissions,
    });

    setChangedPermissions({
      ...changedPermissions,
      [supervisorId]: updatedPermissions,
    });
  };

  const handleVisibilityChange = (supervisorId, targetSupervisorId) => {
    const currentVisibleTo = permissions[supervisorId].visibleTo;
    const updatedVisibleTo = currentVisibleTo.includes(targetSupervisorId)
        ? currentVisibleTo.filter((id) => id !== targetSupervisorId)
        : [...currentVisibleTo, targetSupervisorId];
    const updatedPermissions = {
      ...permissions[supervisorId],
      visibleTo: updatedVisibleTo,
    };

    setPermissions({
      ...permissions,
      [supervisorId]: updatedPermissions,
    });

    setChangedPermissions({
      ...changedPermissions,
      [supervisorId]: updatedPermissions,
    });
  };

  const handleSave = () => {
    setConfirmChanges(true);
    setIsConfirmationOpen(true);
  };

  const handleConfirm = async () => {
    if (confirmChanges) {
      try {
        const supervisorPermissions =
            changedPermissions[selectedSupervisor.employeeId];

        const visibleToIds = supervisorPermissions && supervisorPermissions.visibleTo
            ? supervisorPermissions.visibleTo
            : [];

        const isAdministrator = supervisorPermissions && supervisorPermissions.isAdmin
            ? supervisorPermissions.isAdmin
            : permissions[selectedSupervisor.employeeId].isAdmin;

        const permissionsToSend = {
          supervisorId: parseInt(selectedSupervisor.employeeId),
          isAdmin: isAdministrator,
          visibleTo: visibleToIds,
          successorId: successorId ? parseInt(successorId) : 0
        };
        await axios.post("Ninebox/SavePermissions", permissionsToSend);
      } catch (error) {
        console.error("Error saving permissions:", error);
      }
    }
    setIsConfirmationOpen(false);
    closeModal();
    setConfirmChanges(false);
  };

  const handleCancel = () => {
    setIsConfirmationOpen(false);
  };

  const handleSupervisorChange = (event) => {
    const supervisorId = Number(event.target.value);
    const selectedSupervisor = supervisors.find(
        (supervisor) => supervisor.employeeId === supervisorId
    );
    setSelectedSupervisor(selectedSupervisor);
  };

  useEffect(() => {
    if (isConfirmationOpen && confirmButtonRef.current) {
      confirmButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isConfirmationOpen]);

  const LoadEmployees = async () => {
    let allEmployees = [];
    try {
      const results = await axios.get(`Ninebox/GetTeamMembers?supervisorId=${selectedSupervisor.employeeId}`);
      if (results.data.length > 0) {
        allEmployees = [...allEmployees, ...results.data];
      }
    } catch (error) {
      console.log(error);
    }

    allEmployees.sort((a, b) => a.firstName.localeCompare(b.firstName));
    setEmployees(allEmployees);
  }

  const LoadSuccessor = async () => {
    try {
      const results = await axios.get(`Ninebox/GetSuccessors`);
      if (results.data?.length > 0 && selectedSupervisor?.employeeId) {
        const successorRecord = results.data.find(
            successor => successor.supervisorId === selectedSupervisor.employeeId
        );
        const currentSuccessor = successorRecord?.successorId ?? 0;
        setSuccessorId(currentSuccessor);
      } else {
        setSuccessorId(0);
      }
    } catch (error) {
      console.error('Error loading successor:', error);
      setSuccessorId(0);
    }
  }

  const handleSuccessorChange = (event) => {
    const value = parseInt(event.target.value) || 0;
    setSuccessorId(value);
  }

  return (
      <div>
        <Link to="/">Go Back to Ninebox</Link>
        <h2>Admin Settings</h2>
        <select id="selectionList" className="selectionList" onChange={handleSupervisorChange}>
          {supervisors.map((supervisor) => (
              <option key={supervisor.employeeId} value={supervisor.employeeId}>
                {supervisor.firstName} {supervisor.lastName}
              </option>
          ))}
        </select>
        <button className="submitButtonPrimary" onClick={() => handleEditClick(selectedSupervisor)}>
          Edit
        </button>
        <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Edit Permissions"
        >
          <h2>
            Edit Permissions for{" "}
            {selectedSupervisor
                ? `${selectedSupervisor.firstName} ${selectedSupervisor.lastName}`
                : ""}
          </h2>
          {isLoadingPermissions ? (
              <div>Loading permissions...</div>
          ) : (
              selectedSupervisor && permissions[selectedSupervisor.employeeId] && (
                  <div>
                    <div>
                      <b>Admin Permissions: </b>
                      <input
                          id="admin"
                          type="checkbox"
                          checked={permissions[selectedSupervisor.employeeId]?.isAdmin}
                          onChange={() =>
                              handleAdminChange(selectedSupervisor.employeeId)
                          }
                      />
                      <p className="help-text">
                        Admin permissions provide this supervisor with access to this
                        page.
                      </p>
                    </div>
                    <div className="successor">
                      <b>Successor: </b>
                      <select
                          id="successor"
                          className="successorList"
                          onChange={(e) => handleSuccessorChange(e)}
                          value={successorId}
                      >
                        <option value="" key="0">Select a successor</option>
                        {employees.map((employee) => (
                            <option key={employee.employeeId} value={employee.employeeId}>
                              {employee.firstName} {employee.lastName}
                            </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <b>Visibility Permissions: </b>
                      <p className="help-text">
                        Visibility permissions determine which employees a supervisor
                        can view from other supervisors' teams.
                      </p>
                      <table id="visibility" className="permissions-table">
                        <thead>
                        <tr>
                          <th>Name</th>
                          <th>Visibility permissions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {allSupervisors.map((supervisor, index) => (
                            <tr key={supervisor.employeeId}>
                              <td>
                                {supervisor.firstName} {supervisor.lastName}
                              </td>
                              <td>
                                <input
                                    id={index}
                                    type="checkbox"
                                    checked={permissions[
                                        selectedSupervisor.employeeId
                                        ]?.visibleTo.includes(supervisor.employeeId)}
                                    onChange={() =>
                                        handleVisibilityChange(
                                            selectedSupervisor.employeeId,
                                            supervisor.employeeId
                                        )
                                    }
                                    disabled={selectedSupervisor.employeeId === supervisor.employeeId}
                                />
                              </td>
                            </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                    <button onClick={handleSave}>Save</button>
                    <button onClick={closeModal}>Close</button>
                  </div>
              )
          )}
        </Modal>
        <Modal
            isOpen={isConfirmationOpen}
            onRequestClose={handleCancel}
            contentLabel="Confirmation"
        >
          <h2>Confirmation</h2>
          <p>Are you sure you want to save the changes?</p>
          <button ref={confirmButtonRef} onClick={handleConfirm}>
            Confirm
          </button>
          <button onClick={handleCancel}>Cancel</button>
        </Modal>
      </div>
  );
}

export default AdminSettings;
import { SignOutButton } from "./SignOutButton";
import { SignInButton } from "./SignInButton";
import Navbar from "react-bootstrap/Navbar";
import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { AdminButton } from "./AdminButton";
import { useLocation } from 'react-router-dom';

const Nav = (props) => {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  return (
    <Navbar className={"navBar"} bg="secondary" variant="dark">
        <Link
            style={
                location.pathname === "/Ninebox"
                    ? { display: "inline" }
                    : { display: "none" }
            }
            to="/"
        >
            <FontAwesomeIcon fontSize="36px" color="#666666" icon={faAngleLeft} />
        </Link>
        <Link style={{ textDecoration: "none" }} to={"/"}>
            <Navbar.Brand className={"brandContainer"}>
              <img
                className={"navLogo"}
                src="/Color iGuy Round.png"
                width={50}
                height={50}
                alt="iVenture Logo"
              />
              iVenture Talent Management
            </Navbar.Brand>
        </Link>

        <Navbar.Collapse></Navbar.Collapse>
        <div className={"loginButtonContainer"}>
            {isAuthenticated ? <SignOutButton /> : <SignInButton />}
        </div>
        <div className={"adminButtonContainer"}>
            {(props.isAdmin || props.isViewingSelf) && <AdminButton />}
        </div>
    </Navbar>
  );
};

export default Nav;

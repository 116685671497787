import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";
import './styles.css'
function handleLogout(instance) {
    instance.logoutPopup().catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    return (
        <div className={"loginButtonContainer"}>
            <Button variant="danger" style={{width: '125px'}} className="buttonSecondary" onClick={() => handleLogout(instance)}><b>Sign Out</b></Button>
        </div>
    );
}

import React from "react";
import Button from "react-bootstrap/Button";
import './styles.css';
import { useNavigate } from 'react-router-dom';

export const AdminButton = (props) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/AdminSettings');
    };

    return (
        <div className={"adminButtonContainer"}>
            <Button variant="primary" style={{width: '125px'}} className="buttonPrimary" onClick={handleClick}>Admin</Button>
        </div>
    );
}
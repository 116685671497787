import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "react-bootstrap/Button";
import './styles.css';

function handleLogin(instance, props) {
    instance.loginPopup(loginRequest).catch(e => {
        console.error(e);

        if(e.errorMessage.indexOf("AADSTS50105") >= 0){
            console.error("There was an authentication error.  You may not have permissions to login please see your administrator.");
            props.loginError({hasLoginError: true, loginErrorDetail: 'Error signing in.  You may not have permissions to login please see your administrator.'});
        }
    });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = (props) => {
    const { instance } = useMsal();

    return (
        <div className={"loginButtonContainer"}>
            <Button variant="primary" style={{width: '125px'}} className="buttonPrimary" onClick={() => handleLogin(instance, props)}>Sign in</Button>
        </div>
    );
}

import React from "react";
import Navbar from "react-bootstrap/Navbar";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const PageLayout = (props) => {
    return (
        <>
            <Navbar bg="primary" variant="dark" style={{'color' : '#666666'}}>
                <h3><center>Welcome to the iVenture Talent Management App</center></h3>
                <h4>Please log in</h4>
            </Navbar>
            <br />
            <br />
            {props.children}
        </>
    );
};
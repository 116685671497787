import React, { useState, useEffect } from "react";
import "./App.css";
import Ninebox from "./views/Ninebox";
import AdminSettings from "./views/AdminSettings";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { PageLayout } from "./components/PageLayout";
import Dashboard from "./views/Dashboard";
import Nav from "./components/Nav";
import axios from "./services/axios";

function App() {
  const { accounts } = useMsal();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loggedOnUserEmployeeId, setLoggedOnUserEmployeeId] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [isViewingSelf, setIsViewingSelf] = useState(false);

  // Remove axios instance initialization
  useEffect(() => {
    const fetchData = async () => {
      if (!accounts[0]) return;

      try {
        const loggedOnUsername = accounts[0].username;

        // Get employee ID
        const employeeIdResponse = await axios.get(
            `/Ninebox/Security/GetEmployeeId?username=${loggedOnUsername}`
        );
        const employeeId = employeeIdResponse.data;
        setLoggedOnUserEmployeeId(employeeId);

        // Get admin permissions data
        const adminPermissionsData = (
            await axios.get("/Ninebox/GetAdminPermissions")
        ).data;

        // Check admin status using employee ID
        const isUserAdmin = adminPermissionsData.some(
            admin => admin.supervisorId === employeeId && admin.isAdmin
        );

        setIsAdmin(isUserAdmin);
      } catch (err) {
        console.error("Failed to fetch data:", err);
        setIsAdmin(false);
      }
    };

    fetchData();
  }, [accounts]);

  return (
      <div className="App">
        <header className="App-header">
          <BrowserRouter basename={"/"}>
            <Nav isAdmin={isAdmin} isViewingSelf={isViewingSelf} />
            <UnauthenticatedTemplate>
              <Routes>
                <Route path={"/"} element={<PageLayout />} />
              </Routes>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <Routes>
                <Route
                    path={"/"}
                    element={
                      <Dashboard
                          username={accounts[0]}
                          isAdmin={isAdmin}
                          loggedOnUserEmployeeId={loggedOnUserEmployeeId}
                          onSelfViewChange={setIsViewingSelf}
                      />
                    }
                />
                <Route
                    path={"/Ninebox"}
                    element={
                      <Ninebox
                          username={accounts[0]}
                          loggedOnUserEmployeeId={loggedOnUserEmployeeId}
                      />
                    }
                />
                <Route
                    path={"/AdminSettings"}
                    element={<AdminSettings loggedOnUserEmployeeId={loggedOnUserEmployeeId} />}
                />
              </Routes>
            </AuthenticatedTemplate>
          </BrowserRouter>
        </header>
      </div>
  );
}

export default App;
// src/services/axios.js
import axios from 'axios';

const getBaseUrl = () => {
    // Regular development
    if (process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_API_URL;
    }

    // Check if running under SWA CLI
    if (process.env.NODE_ENV === 'production' && window.location.port === '4280') {
        console.log('Running under SWA CLI');
        return 'http://localhost:7027/api';  // Let SWA CLI handle the routing
    }

    // Production (Azure Static Web Apps)
    return '/api';
};

// Create and configure the axios instance
const instance = axios.create({
    baseURL: getBaseUrl(),
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add request interceptor for request logging and path validation
instance.interceptors.request.use(
    (config) => {
        // Ensure URL starts with '/'
        if (!config.url) {
            console.error('Attempted request without URL path');
            return Promise.reject(new Error('No URL path specified'));
        }

        if (!config.url.startsWith('/')) {
            config.url = '/' + config.url;
        }

        // Remove any duplicate /api prefixes when in production
        if (process.env.NODE_ENV === 'production' && config.url.startsWith('/api/')) {
            config.url = config.url.replace('/api/', '/');
        }

        return config;
    },
    (error) => {
        console.error('Request error:', error);
        return Promise.reject(error);
    }
);

// Add response interceptor for error logging
instance.interceptors.response.use(
    response => response,
    error => {
        console.error('API request failed:', {
            url: error.config?.url,
            baseURL: error.config?.baseURL,
            method: error.config?.method,
            status: error.response?.status,
            data: error.response?.data
        });
        return Promise.reject(error);
    }
);

export default instance;